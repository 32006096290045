<template>
  <div class="home">
    <v-dialog v-model="showDialogPrice" max-width="400" persistent>
      <v-card class="dialog-card pb-3 px-3">
        <v-card-title style="font-size: 1.4rem">
          Увага! Зміна ціни
        </v-card-title>
        <v-card-text class="text-left" style="font-size: 1.1rem; line-height: 1.5rem">
          Доводимо до Вашого відома, що з 01.03.2023 року буде діяти нова ціна на наші послуги.
        </v-card-text>
        <v-card-actions>
          <v-btn small depressed color="grey darken-2" dark class="mr-2" :to="{name: 'PriceChange'}">Ознайомитись
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn small outlined @click.prevent="showDialogPrice = false">Закрити</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showCalc" persistent max-width="400">
      <v-card class="dialog-card pb-3 px-3">
        <v-card-title>
          Калькулятор вартості послуги
        </v-card-title>
        <v-card-text>
          <v-text-field hide-details label="Кількість особових рахунків"
                        placeholder="Введіть кількість особових рахунків"
                        color="grey darken-1"
                        @keyup.enter="calcResult"
                        v-model.number="calc_data.person_id"/>
          <v-btn small depressed color="grey darken-2" dark class="my-4" @click="calcResult" block>
            Розрахувати
          </v-btn>
          <div v-if="calc_data.result_new || calc_data.result_old">
            <div v-if="calc_data.result_old && calc_data.result_new">
              <v-card class="price-box">
                <div class="price-box-title">Вартість поточна</div>
                <div class="price-box-value" v-html="calc_data.result_old"></div>
              </v-card>
              <v-card class="price-box">
                <div class="price-box-title">Вартість нова</div>
                <div class="price-box-value" v-html="calc_data.result_new"></div>
              </v-card>
            </div>
            <div v-if="calc_data.result_old && !calc_data.result_new">
              <v-card class="price-box">
                <div class="price-box-title">Вартість поточна</div>
                <div class="price-box-value" v-html="calc_data.result_old"></div>
              </v-card>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn small outlined @click.prevent="closeCalc">Закрити</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-container fluid>
      <v-row style="background: linear-gradient(90deg, rgba(227,255,231,.6) 0%, rgba(217,231,255,.69) 100%);">
        <section class="title-card" style="position: relative">
          <v-col cols="12" sm="8" md="6">
            <h1
              style="font-size: 2.5rem; font-weight: 600; line-height: 3.2rem; text-align: left; margin-bottom: 34px"
            >
              Платформа для ефективного управління
            </h1>
            <p style="text-align: left; font-size: 1.2rem">
              За допомогою сервісу Ви зможете вести облік нарахувань із утримання та управління будинком,
              комунальних послуг, а також інших послуг чи статтей витрат. Ця платформа дозволить автоматизувати роботу
              бухгалтерам та менеджерам управляючих компаній та ОСББ
            </p>
            <div class="mt-14 text-left">
              <v-btn x-large rounded depressed color="grey darken-2" dark
                     href="https://wetoo.vinksoft.com.ua/login?demo=true"
              >Демонстрація
              </v-btn>
            </div>
          </v-col>
          <div style="position: absolute; right: 0; bottom: 0; top: 0" v-if="$vuetify.breakpoint.smAndUp">
            <img :src="`${$publicPath}img/images/info_1.png`"
                 style="height: 100%; max-width: 500px; opacity: .7; filter: hue-rotate(-100deg);"/>
          </div>
        </section>
      </v-row>
    </v-container>
    <v-container fluid class="grey lighten-4">
      <v-row>
        <section style="margin: 20px auto;">
          <H1
            class="text-center"
            style="text-align: center; font-weight: 600; font-size: 3rem; margin-top: 40px"
          >
            А, що я буду мати?
          </H1>
          <div class="section-wrapper" style="margin-top: 20px">
            <article>
              <v-col cols="12">
                <v-card class="title-card mt-0">
                  <p style="font-size: 1.1rem; text-align: justify;">
                    Ця платформа спеціально розроблена для автоматизації обліку нарахувань в ОСББ,
                    управляючих компаніях та надавачів комунальних послуг. Включає в себе три модулі обліку:
                    Модуль "Розрахунки із населеням (по-квартирний чи по-особовий облік)", Модуль "Розрахунки із
                    юридичними
                    особами
                    (по-особовий та по-договірний облік нарахувань)", Модуль "Бухгалтерський облік (знаходиться в стадії
                    тестування, реліз поп. - 01.03.2023 року)".
                  </p>

                  <p style="font-size: 1.1rem; text-align: justify;">
                    Окрім основних модулів, впроваджено статистику у вигляді графіків, розсилку смс, анонімні
                    повідомлення від мешканців про проблеми у будинку,
                    голосування, кошторис доходів та витрати, фінаннсова звітність, персональний кабінет, особистий
                    сайт,
                    галерея, документи для скачування мешканцям та інші мікросервіси для підвищення ефективності роботи
                    підприємства чи ОСББ
                  </p>
                </v-card>
              </v-col>
              <v-col
                cols="12" sm="12" md="6"
                v-for="(item, idx) in items"
                :key="idx"
              >
                <div class="manual-card"
                     :style="idx === 0 || idx === items.length - 1 ? 'background: linear-gradient(90deg, rgb(208 253 215 / 70%) 0%, rgb(255 248 217 / 69%) 100%);' : ''"
                >
                  <!--              <div class="manual-card-number grey darken-2">{{ item.number }}</div>-->
                  <div class="manual-card-title">{{ item.title }}</div>
                  <div class="manual-card-paragraph">{{ item.text }}</div>
                </div>
              </v-col>
            </article>
          </div>
        </section>
      </v-row>
    </v-container>
    <v-container fluid>
      <v-row style="background: linear-gradient(90deg, rgba(227,248,255,0.6) 0%, rgba(217,225,255,0.69) 100%)">
        <v-col cols="12">
          <h2 style="font-weight: bold; font-size: 3rem; margin-bottom: 0; margin-top: 30px">Telegram BOT</h2>
        </v-col>
        <v-col cols="12" class="pt-0 mb-10">
          <div style="max-width: 1000px; width: 100%; margin: 0 auto">
            <div style="font-weight: 400; font-size: 1.2rem; text-align: center; color: #5e5e5e">
              Ваш персональний помічник - завжди під рукою. Наший bot допоможу Вам швидко отримати
              доступ до корисної інформаії та стану розрахунку по Вашому помешканю
            </div>
            <div class="mt-10">
              <v-btn rounded large color="white--text" style="background-color: rgb(80,162,233)"
                     tag="a"
                     href="https://t.me/Wetoo_bot"
              >
                <v-icon left class="mr-3">mdi-telegram</v-icon>
                Перейти до Telegram
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <h1 style="font-weight: bold; font-size: 3rem; margin-bottom: 0; margin-top: 30px">А скільки вартує?</h1>
        </v-col>
        <v-col cols="12" class="pt-0">
          <div style="max-width: 1200px; width: 100%; margin: 0 auto">
            <div style="font-weight: 400; font-size: 1.2rem; text-align: center; color: #5e5e5e">
              Якщо Ви вирішили долучитися до числа наших клієнтів, тоді маємо для Вас приємну новину
              - перший місяць користування сервісом є безкоштовним. Більше того, ми допоможемо Вам імпортувати
              дані у наший сервіс
            </div>
          </div>
        </v-col>
        <div style="max-width: 900px; width: 100%; margin: 0 auto; display: flex; flex-wrap: wrap"
             class="my-12"
        >
          <v-col cols="12" sm="12" md="6">
            <v-card>
              <v-card-text class="grey lighten-3 grey--text text--darken-2 font-weight-bold pa-12 price"
                           style="font-size: 2rem; line-height: normal">
                до 75 особових рахунків
              </v-card-text>
              <v-card-text style="line-height: normal">
                <div style="font-size: 2rem">Максимальна ціна</div>
                <div style="font-size: 6rem; font-weight: bold">
                  {{ showNewPrice ? 150 : 200 }}
                </div>
                <div style="font-size: 2.6rem">грн./міс.</div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-card>
              <v-card-text class="grey darken-2 white--text font-weight-bold pa-12 price"
                           style="font-size: 2rem; line-height: normal">
                від 76 до 400 особових рахунків
              </v-card-text>
              <v-card-text style="line-height: normal">
                <div style="font-size: 2rem">Максимальна ціна</div>
                <div style="font-size: 6rem; font-weight: bold">
                  {{ showNewPrice ? 800 : 900 }}
                </div>
                <div style="font-size: 2.6rem">грн./міс.</div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="12" class="pb-16">
            <h1 style="font-weight: bold; font-size: 3rem; margin-bottom: 0; margin-top: 30px">А якщо у мене
              більше?</h1>
            <div style="font-weight: 400; font-size: 1.2rem; text-align: center; color: #5e5e5e">
              Якщо у вас більше особових рахунків - не хвилюйтесь ми зможемо з вами домовитися.
              Напишіть або зателефонуйте нам і повірте - ціна Вас здивує. Приблизну вартість обслуговування можна
              дізнатись використавши калькулятор
            </div>
            <div>
              <v-btn x-large rounded depressed color="grey darken-2" dark class="mt-12" @click.prevent="openCalc">
                Калькулятор суми обслуговування
              </v-btn>
            </div>
          </v-col>
        </div>
      </v-row>
    </v-container>
    <v-container fluid>
      <v-row
        style="background: linear-gradient(90deg, rgba(227,255,231,.6) 0%, rgba(217,231,255,.69) 100%); position: relative">
        <div class="title-card" style="position: relative">
          <v-col cols="12" sm="12" md="12">
            <div style="font-size: 2rem; margin-bottom: 10px; font-weight: 500">Хочу підключитися</div>
            <v-alert
              dense
              text
              type="success"
              v-if="sended"
            >
              Дякуємо, що Ви обрали нас. Ваша заявка успішно відправлена.
            </v-alert>
            <v-form v-model="formValid" ref="form">
              <v-row>
                <v-col cols="12" class="py-1">
                  <v-text-field label="Прізвище, ім’я та по-батькові"
                                color="success darken-1"
                                v-model="contact_form.name"
                                :rules="[rules.required]"
                  />
                </v-col>
                <v-col cols="12" class="py-1">
                  <v-text-field label="Назва підприємства"
                                color="success darken-1"
                                v-model="contact_form.organization_name"
                                :rules="[rules.required]"
                  />
                </v-col>
                <v-col cols="12" md="6" class="py-1">
                  <v-text-field label="Код за ЄДРПОУ"
                                color="success darken-1"
                                v-model="contact_form.code"
                                :rules="[rules.required, rules.counter_code]"
                  />
                </v-col>
                <v-col cols="12" md="6" class="py-1">
                  <v-text-field label="Кількість особових рахунків"
                                color="success darken-1"
                                v-model.number="contact_form.accounts"
                                :rules="[rules.required]"
                  />
                </v-col>
                <v-col cols="12" md="12" class="py-1">
                  <v-textarea label="Адреса"
                              color="success darken-1" rows="2"
                              v-model="contact_form.address"
                              :rules="[rules.required]"
                  />
                </v-col>
                <v-col cols="12" md="6" class="py-1">
                  <v-text-field label="Мобільний телефон"
                                color="success darken-1"
                                v-model="contact_form.phone"
                                :rules="[rules.required, rules.phone]"
                  />
                </v-col>
                <v-col cols="12" md="6" class="py-1">
                  <v-text-field label="Email"
                                color="success darken-1"
                                v-model="contact_form.email"
                                :rules="[rules.required, rules.email]"
                  />
                </v-col>
                <v-col cols="12">
                  <v-btn depressed block large color="grey darken-2 white--text" rounded
                         :disabled="!formValid || sended || loading"
                         :loading="loading"
                         @click.stop="send_email"
                  >Підклютися
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
          <v-snackbar
            v-model="message.show"
            :color="message.color"
          >
            {{ message.text }}

            <template v-slot:action="{ attrs }">
              <v-btn
                dark
                text
                v-bind="attrs"
                @click="closeMessage"
              >
                Закрити
              </v-btn>
            </template>
          </v-snackbar>
        </div>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import sendAPI from '@/utils/api'

export default {
  name: 'Home',
  components: {},
  computed: {
    showNewPrice () {
      if (!this.price_change_date.year) return
      const currentDate = new Date()
      const showUntil = new Date()
      showUntil.setFullYear(this.price_change_date.year)
      showUntil.setMonth(this.price_change_date.month)
      showUntil.setDate(this.price_change_date.day)
      return showUntil >= currentDate
    }
  },
  data () {
    return {
      items: [
        {
          title: 'Нарахування будь-яких послуг',
          text: 'Нараховувати можна будь-що починаючи від управління будинком, а закінчуючи теплопостачанням'
        },
        {
          title: 'Монетизація пільг/субсидій',
          text: 'Автоматичне подання інформації та проведення оплат по реєстрах ощадбанку'
        },
        {
          title: 'Одноразові нарахування',
          text: 'Реєстрація одноразових нарахувань фіксованою сумою або по плоші'
        },
        {
          title: 'Завантаження оплат із Єдиної квитанції',
          text: 'Автоматичне завантаження оплат в розрізі послуг, за наявності сервісу "Єдина квитанція"'
        },
        {
          title: 'Завантаження оплат із файлів',
          text: 'Обробка реєстрів і виписок із файлів csv, xls, xlsx, dbf, txt. Для зручності користувача - ' +
            'реалізовано збереження налаштувань.'
        },
        {
          title: 'Лічильники',
          text: 'Облік по приладах обліку (квартирних та будинкових). Реалізовано розподіл показів будинкових лічильників'
        },
        {
          title: 'Звіти',
          text: 'Реалізований необхідний набір звітів, в який входять детальні звіти по абонентах та загальні' +
            'по підприємству'
        },
        {
          title: 'Особистий сайт',
          text: 'Пристня можливість адміністрування власного сайту. Відображення документів, заборгованості, ' +
            'новин, оголошень та ін.'
        },
        {
          title: 'Персональний кабінет',
          text: 'Кожен абонент може використовувати персональний кабінет для контролю нарахувань, оплати, приймати' +
            'участь в голосуваннях та обговореннях, а також оплатити надані послуги - online'
        },
        {
          title: 'Друк квитанцій',
          text: 'На вибір користувача є два види квитанцій: деталізований та спрощений. Ви можете обрати будь-яку - ' +
            'налаштувати параметри відбору та надрукувати, або зберегти.'
        }
      ],
      contact_form: {
        name: '',
        organization_name: '',
        code: '',
        accounts: 0,
        address: '',
        phone: '',
        email: ''
      },
      price_change_date: {
        year: 2023,
        month: 2,
        day: 1
      },
      calc_data: {
        person_id: 0,
        result_old: '',
        result_new: ''
      },
      formValid: false,
      rules: {
        required: value => !!value || 'Це поле обов’язкове.',
        counter_code: value => value.length >= 10 || 'Мінімум 10 символів',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Невірний e-mail.'
        },
        phone: value => {
          const pattern = /((\+)?\b(8|38)?(0[\d]{2}))([\d-]{5,8})([\d]{2})/
          return pattern.test(value) || 'Невірний мобільний телефон.'
        }
      },
      message: {
        text: '',
        color: 'success',
        show: false
      },
      loading: false,
      sended: false,
      showDialogPrice: false,
      showCalc: false
    }
  },
  methods: {
    showChangePrice () {
      if (this.showNewPrice) {
        const vm = this
        setTimeout(() => {
          vm.showDialogPrice = true
        }, 3000)
      }
    },
    roundUp (number) {
      return Math.round((number + Number.EPSILON) * 100) / 100
    },
    calcResult () {
      let result = 0
      let resultNew = 0

      if (this.showNewPrice) {
        if (this.calc_data.person_id > 0 && this.calc_data.person_id <= 75) {
          result = 150
          resultNew = 200
        }
        if (this.calc_data.person_id > 75 && this.calc_data.person_id <= 400) {
          result = this.roundUp(this.calc_data.person_id * 2)
          resultNew = this.roundUp(this.calc_data.person_id * 2.25)
        }
        if (this.calc_data.person_id > 400 && this.calc_data.person_id <= 600) {
          result = this.roundUp(this.calc_data.person_id * 1.8)
          resultNew = this.roundUp(this.calc_data.person_id * 1.9)
        }
        if (this.calc_data.person_id > 600 && this.calc_data.person_id <= 1000) {
          result = this.roundUp(this.calc_data.person_id * 1.6)
          resultNew = this.roundUp(this.calc_data.person_id * 1.75)
        }
        if (this.calc_data.person_id > 1000 && this.calc_data.person_id <= 1500) {
          result = this.roundUp(this.calc_data.person_id * 1.3)
          resultNew = this.roundUp(this.calc_data.person_id * 1.4)
        }
        if (this.calc_data.person_id > 1500) {
          result = 9999999999
          resultNew = 9999999999
        }
      } else {
        if (this.calc_data.person_id > 0 && this.calc_data.person_id <= 75) {
          result = 200
        }
        if (this.calc_data.person_id >= 75 && this.calc_data.person_id <= 400) {
          result = this.roundUp(this.calc_data.person_id * 2.25)
        }
        if (this.calc_data.person_id > 400 && this.calc_data.person_id <= 600) {
          result = this.roundUp(this.calc_data.person_id * 1.9)
        }
        if (this.calc_data.person_id > 600 && this.calc_data.person_id <= 1000) {
          result = this.roundUp(this.calc_data.person_id * 1.75)
        }
        if (this.calc_data.person_id > 1000 && this.calc_data.person_id <= 1500) {
          result = this.roundUp(this.calc_data.person_id * 1.4)
        }
        if (this.calc_data.person_id > 1500) {
          result = 9999999999
        }
      }
      if (resultNew) {
        if (resultNew === 9999999999) {
          this.calc_data.result_new = 'Договірна'
        } else {
          this.calc_data.result_new = `<span class="price-span">${resultNew}</span> <br>  грн. в місяць`
        }
      }
      if (result) {
        if (result === 9999999999) {
          this.calc_data.result_old = 'Договірна'
        } else {
          this.calc_data.result_old = `<span class="price-span">${result}</span> <br> грн. в місяць`
        }
      }
    },
    openCalc () {
      this.showCalc = true
      this.calc_data.person_id = 0
      this.calc_data.result_old = ''
      this.calc_data.result_new = ''
    },
    closeCalc () {
      this.showCalc = false
      this.calc_data.person_id = 0
      this.calc_data.result_old = ''
      this.calc_data.result_new = ''
    },
    closeMessage () {
      this.message.text = ''
      this.message.show = false
      this.message.color = 'success'
    },
    send_email () {
      if (this.sended) return
      this.closeMessage()
      this.loading = true
      sendAPI.send_email(Object.assign({}, this.contact_form))
        .then(response => response.data)
        .then(() => {
          this.sended = true

          this.message.text = 'Дякуємо! Ми з Вами зв’яжимось'
          this.message.show = true
          this.message.color = 'success'
        })
        .catch(err => {
          this.message.text = err.response.data.detail
          this.message.show = true
          this.message.color = 'success'
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  created () {
    this.showChangePrice()
  }
}
</script>

<style lang="scss" scoped>
.manual-card {
  position: relative;
  padding: 26px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
  background-color: #ffffff;

  .manual-card-title {
    font-size: 1.4rem;
    font-weight: 600;
    text-align: left;
    margin-bottom: 10px;
  }

  .manual-card-paragraph {
    font-size: 1rem;
    font-weight: 400;
    text-align: left;
  }

  .manual-card-number {
    position: absolute;
    border-radius: 50%;
    right: 40px;
    font-size: 1.5rem;
    font-weight: bold;
    top: -20px;
    width: 60px;
    height: 60px;
    color: #ffffff;
    line-height: 76px;
    text-align: center;
  }
}

.price {
  //background: linear-gradient(90deg, rgb(252 253 208 / 96%) 0%, rgb(217 221 255 / 50%) 100%)
}
</style>
