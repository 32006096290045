import Axios from 'axios'

const PROTOCOL = 'http://'
const URL_PRODUCTION = 'wetoo-api.vinksoft.com.ua'
const URL_DEVELOPMENT = '127.0.0.1:8010'

const AXIOS_BASE_URL = `${PROTOCOL}${process.env.NODE_ENV === 'production' ? URL_PRODUCTION : URL_DEVELOPMENT}`

Axios.defaults.baseURL = AXIOS_BASE_URL

export const axios = Axios
export const BASE_URL_AXIOS = AXIOS_BASE_URL
